import React from "react"
import { graphql } from "gatsby"

import SEO from "../components/seo"
import Header from "components/header"
import SubPageNav from "components/subPageNav"
import ImpactArea from "components/sections/impactArea"
import Sections from "components/sections"
import Footer from "components/footer"

const PageTemplate = ({ data }) => {
  const parentData = data.wpPage
  const pageData = data.allWpPage.edges[0].node

  const parentSlug = parentData.slug

  // Show the tested navigation?
  const showSubNav = (parentSlug === 'giveaways-2')
    ? false
    : true

  return (
    <div>
      <SEO lang="en-US" title={pageData.seo.title} data={pageData.seo} />
      <main>
        <Header />
        <ImpactArea data={parentData} />
        {showSubNav &&
          <SubPageNav
            items={parentData.wpChildren.nodes}
            currentPage={pageData.databaseId}
          />
        }
        <div className="container sub-page-heading">
          <h1 style={{ paddingTop: "4rem" }}>{pageData.title}</h1>
        </div>
        {pageData.sections.sections &&
          <Sections data={pageData.sections.sections} />
        }
      </main>
      <Footer />
    </div>
  )
}
export default PageTemplate

export const postQuery = graphql`
  query SubPageQuery($id: Int!, $parentId: Int!) {
    allWpPage(filter: { databaseId: { eq: $id } }) {
      edges {
        node {
          id
          databaseId
          title
          date
          title
          slug
          seo {
            title
            opengraphAuthor
            focuskw
            canonical
            opengraphDescription
            metaRobotsNofollow
            metaDesc
            metaKeywords
            opengraphImage {
              mediaItemUrl
            }
            opengraphModifiedTime
            opengraphPublishedTime
            opengraphPublisher
            opengraphSiteName
            opengraphTitle
            opengraphType
            opengraphUrl
          }
          parentId
          parentDatabaseId
          sections {
            fieldGroupName
            sections {
              ... on WpPage_Sections_Sections_HighlightBlock {
                bodyText
                highlights {
                  header
                  subheader
                  link
                  image {
                    localFile {
                      publicURL
                    }
                    mediaItemUrl
                  }
                }
                heading
                cardPerRow
                fieldGroupName
                imageConstraints
                addImageBackground
              }
              ... on WpPage_Sections_Sections_HomeImpactArea {
                bodyText
                fieldGroupName
                heading
                vibesets {
                  ... on WpVibeset {
                    title
                    id
                    slug
                    vibesetDetails {
                      exploreLink
                    }
                  }
                }
                links {
                  email
                  externalUrl
                  fieldGroupName
                  forceDownload
                  linkText
                  linkType
                  pageLink {
                    ... on WpPage {
                      id
                      uri
                    }
                    ... on WpPost {
                      id
                      uri
                    }
                  }
                  file {
                    id
                    localFile {
                      name
                      ext
                      publicURL
                    }
                  }
                }
              }
              ... on WpPage_Sections_Sections_AppStoreCallout {
                text
                appStoreUrl
                fieldGroupName
                googlePlayUrl
                image {
                  altText
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        layout: CONSTRAINED,
                        placeholder: BLURRED
                      )
                    }
                  }
                }
              }
              ... on WpPage_Sections_Sections_CalloutBlock {
                fieldGroupName
                heading
                style
                bodyText
                links {
                  externalUrl
                  fieldGroupName
                  forceDownload
                  linkText
                  linkType
                  pageLink {
                    ... on WpPage {
                      id
                      uri
                    }
                    ... on WpPost {
                      id
                      uri
                    }
                  }
                  file {
                    id
                    localFile {
                      name
                      ext
                      publicURL
                    }
                  }
                }
              }
              ... on WpPage_Sections_Sections_CitiesBlock {
                heading
                bodyText
                cardPerRow
                imageType
                fieldGroupName
                cities {
                  ... on WpCity {
                    id
                    cityDetails {
                      icon {
                        databaseId
                        localFile {
                          publicURL
                        }
                      }
                      photos {
                        mediaItemUrl
                      }
                      placemarker {
                        latitude
                        longitude
                        zoom
                      }
                      vibes {
                        slug
                        name
                      }
                    }
                    title
                    uri
                  }
                }
                links {
                  externalUrl
                  fieldGroupName
                  forceDownload
                  linkText
                  linkType
                  pageLink {
                    ... on WpPage {
                      id
                      uri
                    }
                    ... on WpPost {
                      id
                      uri
                    }
                  }
                  file {
                    id
                    localFile {
                      name
                      ext
                      publicURL
                    }
                  }
                }
              }
              ... on WpPage_Sections_Sections_FeaturedPost {
                fieldGroupName
                heading
                featuredPost {
                  ... on WpPost {
                    id
                    databaseId
                    title
                    slug
                    uri
                    date
                    content
                    excerpt
                    featuredImage {
                      node {
                        caption
                        mediaItemUrl
                      }
                    }
                    author {
                      node {
                        name
                      }
                    }
                    categories {
                      nodes {
                        name
                      }
                    }
                    postDetails {
                      authorName
                      authorBio
                      customAuthor
                      vibeset {
                        ... on WpVibeset {
                          id
                          slug
                          title
                          databaseId
                          vibesetDetails {
                            gradientImage {
                              mediaItemUrl
                            }
                            gradientVideo {
                              mediaItemUrl
                            }
                          }
                        }
                      }
                      city {
                        ... on WpCity {
                          id
                          title
                          uri
                          slug
                          databaseId
                        }
                      }
                    }
                  }
                }
                posts {
                  ... on WpPost {
                    id
                    databaseId
                    title
                    slug
                    uri
                    date
                    content
                    excerpt
                    featuredImage {
                      node {
                        caption
                        mediaItemUrl
                      }
                    }
                    author {
                      node {
                        name
                      }
                    }
                    categories {
                      nodes {
                        name
                      }
                    }
                    postDetails {
                      vibeset {
                        ... on WpVibeset {
                          id
                          slug
                          title
                          databaseId
                          vibesetDetails {
                            gradientImage {
                              mediaItemUrl
                            }
                            gradientVideo {
                              mediaItemUrl
                            }
                          }
                        }
                      }
                      city {
                        ... on WpCity {
                          id
                          title
                          uri
                          slug
                          databaseId
                        }
                      }
                    }
                  }
                }
              }
              ... on WpPage_Sections_Sections_ImageBlock {
                fieldGroupName
                image {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        layout: CONSTRAINED
                      )
                    }
                  }
                }
              }
              ... on WpPage_Sections_Sections_StoryBlock {
                fieldGroupName
                blocks {
                  bodyText
                  style
                  caption
                  image {
                    altText
                    localFile {
                      childImageSharp {
                        gatsbyImageData(
                          layout: CONSTRAINED
                        )
                      }
                    }
                  }
                  vibeset {
                    ... on WpVibeset {
                      id
                      slug
                      title
                      databaseId
                      vibesetDetails {
                        gradientImage {
                          mediaItemUrl
                        }
                        gradientVideo {
                          mediaItemUrl
                        }
                      }
                    }
                  }
                }
              }
              ... on WpPage_Sections_Sections_QuotesBlock {
                fieldGroupName
                heading
                quotes {
                  attribution
                  fieldGroupName
                  quote
                }
              }
              ... on WpPage_Sections_Sections_FormBlock {
                fieldGroupName
                form
                heading
                text
              }
              ... on WpPage_Sections_Sections_TextWithForm {
                fieldGroupName
                heading
                bodyText
                layout
                form
              }
              ... on WpPage_Sections_Sections_TextColumnsIcon {
                fieldGroupName
                columns {
                  bodyText
                  fieldGroupName
                  heading
                  iconSize
                  icon {
                    altText
                    localFile {
                      childImageSharp {
                        gatsbyImageData(
                          layout: CONSTRAINED
                        )
                      }
                    }
                  }
                }
              }
              ... on WpPage_Sections_Sections_TextWithImage {
                bodyText
                fieldGroupName
                heading
                layout
                textStyles
                style
                caption
                image {
                  altText
                  caption
                  localFile {
                    publicURL
                  }
                }
                vibeset {
                  ... on WpVibeset {
                    id
                    slug
                    vibesetDetails {
                      gradientImage {
                        mediaItemUrl
                      }
                      gradientVideo {
                        mediaItemUrl
                      }
                    }
                  }
                }
                links {
                  externalUrl
                  fieldGroupName
                  forceDownload
                  linkText
                  email
                  linkType
                  pageLink {
                    ... on WpPage {
                      id
                      uri
                    }
                    ... on WpPost {
                      id
                      uri
                    }
                  }
                  file {
                    id
                    localFile {
                      name
                      ext
                      publicURL
                    }
                  }
                }
              }
              ... on WpPage_Sections_Sections_TextWithImageForm {
                fieldGroupName
                heading
                bodyText
                form
                image {
                  altText
                  caption
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        layout: CONSTRAINED
                      )
                    }
                  }
                }
                layout
                links {
                  externalUrl
                  fieldGroupName
                  forceDownload
                  linkText
                  linkType
                  formId
                  pageLink {
                    ... on WpPage {
                      id
                      uri
                    }
                    ... on WpPost {
                      id
                      uri
                    }
                  }
                  file {
                    id
                    localFile {
                      name
                      ext
                      publicURL
                    }
                  }
                }
              }
              ... on WpPage_Sections_Sections_TextWithImageCallout {
                fieldGroupName
                heading
                bodyText
                layout
                image {
                  caption
                  altText
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        layout: CONSTRAINED
                      )
                    }
                  }
                }
                links {
                  externalUrl
                  fieldGroupName
                  forceDownload
                  linkText
                  linkType
                  formId
                  pageLink {
                    ... on WpPage {
                      id
                      uri
                    }
                    ... on WpPost {
                      id
                      uri
                    }
                  }
                  file {
                    id
                    localFile {
                      name
                      ext
                      publicURL
                    }
                  }
                }
                callout {
                  heading
                  bodyText
                  calloutLinks {
                    externalUrl
                    fieldGroupName
                    forceDownload
                    linkText
                    linkType
                    formId
                    pageLink {
                      ... on WpPage {
                        id
                        uri
                      }
                      ... on WpPost {
                        id
                        uri
                      }
                    }
                    file {
                      id
                      localFile {
                        name
                        ext
                        publicURL
                      }
                    }
                  }
                }
              }
              ... on WpPage_Sections_Sections_VibesetsBlock {
                bodyText
                fieldGroupName
                heading
                vibesets {
                  vibeset {
                    ... on WpVibeset {
                      id
                      slug
                      title
                      databaseId
                      vibesetDetails {
                        exploreLink
                        tagline
                        description
                        mainVibes
                        gradientImage {
                          altText
                          mediaItemUrl
                        }
                        gradientVideo {
                          mediaItemUrl
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    wpPage(databaseId: { eq: $parentId }) {
      id
      databaseId
      slug
      title
      date
      wpChildren {
        nodes {
          ... on WpPage {
            databaseId
            menuOrder
            title
            uri
            slug
          }
        }
      }
      impactArea {
        textColor
        backgroundColor
        backgroundType
        backgroundImage {
          localFile {
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
              )
            }
          }
        }
        heading
        vibeset {
          ... on WpVibeset {
            id
            slug
            title
            databaseId
            vibesetDetails {
              tagline
              description
              gradientImage {
                mediaItemUrl
              }
              gradientVideo {
                mediaItemUrl
              }
            }
          }
        }
      }
    }
  }
`