import React, { useMemo } from "react"

import PropTypes from "prop-types"
import "./subPageNav.scss"

const SubPageNav = ({ items, currentPage }) => {
  const sortedItems = useMemo(() => {
    // Sort by wp's menu order
    return items.sort((a, b) => {
      return a.menuOrder - b.menuOrder
    })
  }, [items])

  return (
    <nav className="sub-page-menu">
      <div className="container">
        <ul>
          {sortedItems.map((item, i) => {
            return (
              <li
                key={`${i}_${item.slug}`}
                className={
                  (item.databaseId === currentPage) && `current-page`
                }
              >
                <a href={item.uri} title={item.title}>
                  {item.title}
                </a>
              </li>
            )
          })}
        </ul>
      </div>
    </nav>
  )
}

export default SubPageNav

SubPageNav.propTypes = {
  items: PropTypes.array.isRequired,
  currentPage: PropTypes.number.isRequired,
}
